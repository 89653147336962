@mixin typeNazi($tag) {
  @if $tag == Title {
    font-size: 29px;
    line-height: 36px;
    font-family: $font-open-sans-condensed;
    font-weight: $font-weight-bold;
  }
  @if $tag == subTitle {
    font-size: 24px;
    line-height: 34px;
    font-family: $font-open-sans-condensed;
    font-weight: $font-weight-bold;
  } @else if $tag == p {
    font-size: 16px;
    line-height: 24px;
  }
  @else if $tag == contentP {
    font-size: 16px;
    line-height: 24px;
  }
}

h1 {
  @include typeNazi(Title);
}

h2 {
  @include typeNazi(subTitle);
}

p {
  margin: 0 0 20px 0;
  @include typeNazi(p);
}

p:last-child {
  margin-bottom: 0;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}