body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: $font-open-sans-regular;
  @include typeNazi(p);
  background-color: $colour-light-grey;
}

a {
  color: $colour-black;
  transition: all .5s ease-in-out 0s;
}

.clearfix {
  clear: both;
}

.wrapper {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  background-color: $colour-white;
  @media (min-width: 768px) {
    max-width: 1280px;
    padding: 0 20px 20px 20px;
  }
}

.post {
  max-width: 1000px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 0;
  clear: left;
  .post-title,
  h1,
  h2,
  h3 {
    margin: 0 0 20px 0;
  }
}

p {
  @include typeNazi(contentP);
}

ol {
  list-style-type: decimal;
  margin: 0 0 10px 20px;
  li {
    margin: 0 0 12px 0;
  }
}

ul {
  margin: 0 0 10px 0;
  li {
    white-space:normal;
    background-image: url('#{$assets_path}img/arrow-right-red.svg');
    background-repeat: no-repeat;
    background-position: left 4px;
    background-size: 15px;
    padding: 0 0 12px 20px;
    @include typeNazi(p);
    font-family: $font-open-sans-condensed;
  }
}

b,
strong {
  font-family: $font-open-sans-condensed;
}