*, :after, :before {
  box-sizing: border-box;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  @media (min-width: 1024px) {
    max-width: 1280px;
  }
}

.container:after, .container:before {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

$assets_path: "../assets/";