.hero-banner-bg-link {
  display: block;
}
.hero-banner-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 200px;
  @media (min-width: 767px) {
    height: 350px;
  }
}

.hero-banner-bg-text-wrapper {
  width: 100%;
  h1 {
    font-size: 40px;
    line-height: 48px;
  }
  h1,
  h2 {
    color: $colour-white;
  }
  span {
    padding: 8px 20px 8px 20px;
    margin: 10px 0 0 0;
    text-transform: none;
    &:after {
      display: inline-block;
      height: 13px;
      width: 13px;
      content: '';
      background-image:url('#{$assets_path}img/arrow-right-white.svg');
      background-repeat: no-repeat;
      background-position: 100%;
      background-size: 13px;
      margin-left: 10px;
      vertical-align: middle;
    }
    &:hover {
      &:after {
        background-image:url('#{$assets_path}img/arrow-right-white.svg');
      }
    }
  }
  &.red {
    h1,
    h2 {
      color: $colour-red;
    }
  }
  &.white {
    h1,
    h2 {
      color: $colour-white;
    }
  }
  &.black {
    h1,
    h2 {
      color: $colour-black;
    }
  }
  &.hidden-xs {
    display: none;
  }
  &.visible-xs {
    display: block;
    background-color: $colour-light-grey;
    padding: 20px;
  }
  @media (min-width: 767px) {
    display: table;
    height: 350px;
    padding: 20px 10px;
    &.hidden-xs {
      display: table;
    }
    &.visible-xs {
      display: none;
    }
    .hero-banner-bg-text {
      display: table-cell;
      vertical-align: middle;
    }
  }
  @media (min-width: 1024px) {
    padding: 20px 70px;
  }
  @media (min-width: 767px) and (max-width: 1024px) {
    padding: 20px 30px;
  }
}

.page-index {
  .hero-banner-bg-curve {
    &.hidden-xs {
      display: none;
      @media (min-width: 767px) {
        display: block;
        height: 87px;
      }
    }
  }
}
.hero-breadcrumb {
  background-color: $colour-light-grey;
  padding: 20px;
  border-bottom: 1px solid $colour-mid-grey;
  float: left;
  width: 100%;
  .btn {
    background: $colour-black url("#{$assets_path}img/arrow-left-white.svg") no-repeat 11px 13px;
    background-size: 15px;
    padding: 8px 20px 8px 35px;
    &:hover {
      background: $colour-mid-grey url("#{$assets_path}img/arrow-left-white.svg") no-repeat 11px 13px;
      background-size: 15px;
    }
  }
  p {
    display: inline-block;
    margin: 5px 10px 0 0;
    float: left;
  }
  .hero-wrapper {
    display: inline-block;
    float: right;
    @media (max-width: 540px) {
      float: left;
      width: 100%;
      display: block;
      margin: 15px 0 0 0;
    }
  }
  .season,
  .vehicle-type {
    display: inline-block;
    float: right;
    margin-top:10px;
    p {
      color: $colour-mid-grey;
      text-transform: uppercase;
      font-family: $font-open-sans-condensed;
      display: none;
      @media (min-width: 767px) {
        display: inline-block;
      }
    }
    img {
      width: 30px;
    }
    @media (max-width:540px) {
      display: block;
    }
  }
  @media (max-width:540px) {
    .season {
      float: left;
    }
  }
  .vehicle-type {
    margin-left: 10px;
    @media (min-width: 767px) {
      margin-left: 20px;
    }
    img {
      width: 50px;
      margin-right: 10px;
      margin-top:3px;
      vertical-align: middle;
    }
  }
}