.tyre {
  max-width: 1000px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 0;
  clear: left;
  .post-content {
    h1.post-title,
    h2.post-sub-title {
      margin: 0;
    }
    h2.post-sub-title {
      @include typeNazi(subTitle);
    }
  }
  .content {
    margin: 40px 0;
    display: inline-block;
    @media (min-width: 933px) {
      margin-right: 50px;
      width: 55%;
    }
    ul {
      margin: 0 0 30px 0;
    }
  }
  h1,
  h2,
  h3 {
    margin: 0 0 20px 0;
  }
}

.awards {
  display: inline-block;
  vertical-align: top;
  margin: 40px 0 0 0;
  @media (min-width: 933px) {
    float: right;
    margin: 0;
  }
  .award-img {
    display: block;
    width: 120px;
    @media (min-width: 400px) {
      // margin: 0 auto;
    }
  }
  .tyre-img {
    float: left;
    margin-right: 40px;
    width: 190px;
    margin-bottom: 30px;
    @media (min-width: 400px) {
      margin-bottom: 0;
    }
  }
}

.available-sizes {
  max-width: 1138px;
  width: 100%;
  margin: 0 auto 80px auto;
  padding: 40px 15px;
  background-color: $colour-light-grey;
  border-top: 1px solid $colour-mid-grey;
  border-bottom: 1px solid $colour-mid-grey;
  @media (min-width: 1160px) {
    padding: 40px 80px;
  }
  h2 {
    @include typeNazi(Title);
    margin: 0 0 30px 0;
  }
  span {
    background-color: $colour-white;
    display: inline-block;
    padding: 14px 30px;
    border:2px solid $colour-black;
    text-align: center;
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 10px;
    @media (min-width: 767px) {
      margin-right: 10px;
    }
  }
}

.cta {
  background-color: $colour-mid-grey;
  position: relative;
  color: $colour-white;
  //margin: 20px 0 80px 0;
  clear: both;
  padding: 30px;
  text-align: center;
  h2 {
    @include typeNazi(Title);
    text-transform: none;
    color: $colour-white;
  }
  p {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    margin: 10px 0 20px 0;
    padding: 0 30px;
    font-family: $font-open-sans-condensed;
  }
  a[href^="tel:"] {
    color: $colour-white;
  }
}