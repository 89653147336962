header {
  width: 100%;
  position: relative;
  .site-logo {
    height: auto;
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 48%;
    @media (min-width: 768px) {
      margin: 0 0 0 30px;
      width: 240px;
    }
    @media (min-width: 1024px) {
      margin: 0 0 0 70px;
    }
    a {
      display: inline-block;
      background: url("#{$assets_path}img/logo_matador.svg") no-repeat left;
      width: 200px;
      height: 55px;
      background-size:contain;
      @media (min-width: 768px) {
        display: block;
        width: 240px;
        height: 100px;
        background-size: contain;
      }
    }
  }
  .profitlink-wrapper {
    display: inline-block;
    width: 50%;
    @media (min-width: 768px) {
      float: right;
      margin-top: 12px;
      width: auto;
    }
  }
  .profitlink-text {
    float: left;
    height: 69px;
    line-height: 69px;
    color: $colour-mid-grey;
    text-transform: uppercase;
    margin-right: 20px;
    display: none;
    font-family: $font-open-sans-condensed;
    @media (min-width: 800px) {
      display: inline-block;
    }
  }
  .profitlink-logo {
    display: inline-block;
    float: right;
    a {
      display: inline-block;
      img {
        float: right;
        width: 135px;
        @media (min-width: 768px) {
          width: 230px;
        }
      }
    }
  }
}