footer {
  float: left;
  width: 100%;
  background: url("#{$assets_path}img/curve.jpg") no-repeat top;
  .site-footer {
    margin: 87px 0 30px 0;
  }
  p{
    font-family: $font-open-sans-condensed;
    margin: 0 0 30px 0;
  }
  ul {
    margin: 50px 0 20px 0;
    li {
      background: none;
      padding: 0;
      @media (min-width: 768px) {
        display: inline-block;
      }
      a {
        font-family: $font-open-sans-condensed;
        color: $colour-black;
        text-transform: uppercase;
        @include typeNazi(p);
        @media (min-width: 768px) {
          &:after {
            display: inline-block;
            padding: 0 10px;
            content: '|';
            &:last-child {
              content: "";
            }
          }
        }
        &:hover {
          color: $colour-red;
          &:after {
            color: $colour-black;
          }
        }
        &.last {
          &:after {
            content: '';
          }
        }
      }
    }
  }
}

.template-homepage {
  .site-footer {
    margin: 87px 0 30px 0;
    @media (min-width: 1024px) {
      margin: 87px 70px 30px 70px;
    }
  }
}

.template-default,
.template- {
  .site-footer {
    margin: 87px 0 30px 0;
    @media (min-width: 1024px) {
      max-width: 1000px;
      margin: 87px auto 30px auto;
    }
  }
}