.btn {
  display: inline-block;
  background-color: $colour-black;
  padding: 10px 20px;
  color: $colour-white;
  transition: all .5s ease-in-out 0s;
  font-family: $font-open-sans-condensed;
  text-transform: uppercase;
  &:hover {
    background-color: $colour-mid-grey;
    color: $colour-white;
  }
}