.page-product-downloads {
  .post-content {
    ul {
      float: left;
      width: 100%;
      margin: 0 0 30px 0;
      padding: 0 0 30px 0;
      border-bottom: 1px solid $colour-light-grey;
      &:last-child {
        margin: 0;
        border-bottom: 0;
      }
      li {
        @media (min-width:640px) {
          width: calc(50%);
          float: left;
        }
        @media (min-width:992px) {
          width: calc(33.333333333%);
          float: left;
        }
        &:last-child {
          margin-bottom: 0;
        }
        a {
          color: $colour-black;
          display: block;
          &:hover {
            color: $colour-red;
          }
        }
      }
    }
  }
}