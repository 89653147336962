.home {
  .tyre-carousel-wrapper {
    margin: 0 0 20px 0;
    text-align: center;
    position: relative;
    //float: left;
    width: 100%;
    padding: 0 0 30px 0;
    &:first-child {
      margin: 20px 0;
    }
    .title {
      text-align: left;
      margin-bottom: 40px;
    }
    a {
      color: $colour-black;
      font-family: $font-open-sans-condensed;
      font-weight: $font-weight-bold;
      font-size: 18px;
      line-height: 24px;
    }
    h2 {
      padding: 20px 0;
      @include typeNazi(Title);
      color: $colour-mid-grey;
      text-transform: none;
      border-bottom: 1px solid $colour-mid-grey;
    }
    .tyre-item {
      padding: 15px 0;
      border-bottom: 1px solid $colour-mid-grey;
      img {
        display: block;
        margin: 0 auto 20px auto;
        width: 150px;
      }
    }
    &#all-season {
      margin: 0 0 20px 0;
      @media (min-width: 1200px) {
        width: 50%;
        margin-right: 15px;
      }
      .tyre-item {
        img {
          width: 155px;
        }
      }
    }
  }
  .product-downloads {
    background-color: $colour-light-grey;
    float: right;
    width: 100%;
    margin-bottom: 20px;
    @media (min-width: 1200px) {
      width: 48%;
      margin-top:20px;
    }
    .title {
      background-color: $colour-white;
      border-bottom: 1px solid $colour-white;
    }
    h2 {
      @include typeNazi(Title);
      color: $colour-mid-grey;
      text-transform: none;
      margin: 0 0 20px 0;
      a {
        color: $colour-mid-grey;
      }
    }
    ul {
      float: left;
      width: 100%;
      padding: 20px;
      li {
        background-image: url('#{$assets_path}img/arrow-right-red.svg');
        @media (min-width:640px) {
          width: calc(50%);
          float: left;
        }
        &:last-child {
          margin-bottom: 0;
        }
        a {
          color: $colour-red;
          font-size: 18px;
          line-height: 22px;
          font-family: $font-open-sans-condensed;
          font-weight: $font-weight-bold;
          display: block;
          &:hover {
            color: $colour-mid-grey;
          }
        }
      }
    }
  }
}

.no-flexbox {
  .home {
    #all-season {
      float: left;
      display: inline-block;
    }
    .product-downloads {
      display: inline-block;
      @media (min-width: 1200px) {
        width: 48%;
        min-height: 422px;
      }
    }
  }
}
.flexbox {
  .home {
    .tyre-carousel {
      display: flex;
      display: -webkit-flex;
      display: -ms-flexbox;
      flex-wrap: wrap;
      width: 100%;
      @media (min-width: 1024px) {
        padding: 0 70px;
      }
    }
    .tyre-carousel-wrapper,
    .product-downloads {
      //flex-basis: 0;
      //flex-grow: 1;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      max-width: 100%;
    }
  }
}

.slick-slide {
  margin: 0 15px;
}